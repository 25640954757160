// src/components/FileRepository.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';
import FileUploadModal from './FileUploadModal';
import FileCard from './FileCard';

const FileRepository = () => {
  const { session } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const fetchCategories = async () => {
    const { data, error } = await supabase.from('categories').select('*');
    if (error) {
      console.error('Erro ao buscar categorias:', error);
    } else {
      setCategories(data);
      // Inicialmente, todas as categorias estão selecionadas
      setSelectedCategories(new Set(data.map((category) => category.id)));
    }
  };

  const fetchFiles = async () => {
    if (!session) return;

    let query = supabase
      .from('files')
      .select(`*, categories ( name )`)

    if (selectedCategories.size > 0) {
      query = query.in('category_id', Array.from(selectedCategories));
    }

    const { data, error } = await query;
    if (error) {
      console.error('Erro ao buscar arquivos:', error);
    } else {
      setFiles(data);
    }
  };

  const handleFileDeleted = () => {
    fetchFiles();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [selectedCategories, session]);

  const toggleCategory = (categoryId) => {
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(categoryId)) {
      newSelectedCategories.delete(categoryId);
    } else {
      newSelectedCategories.add(categoryId);
    }
    setSelectedCategories(newSelectedCategories);
  };

  return (
    
    <div className="p-6">
      {/* Botão para abrir o modal de upload */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setIsUploadModalOpen(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Upload de Arquivo
        </button>
      </div>

      {/* Modal de Upload */}
      <FileUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUploadSuccess={fetchFiles}
      />

      {/* Botões de Categorias */}
      <div className="flex flex-wrap space-x-2 mb-6">
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() => toggleCategory(category.id)}
            className={`px-4 py-2 rounded-full mb-2 ${
              selectedCategories.has(category.id)
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Exibição dos Arquivos */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {files.map((file) => (
          <FileCard
            key={file.id}
            file={file}
            onDelete={handleFileDeleted}
          />
        ))}
      </div>
      {files.length === 0 && (
        <p className="text-gray-700 mt-4">Nenhum arquivo encontrado.</p>
      )}
    </div>
  );
};

export default FileRepository;

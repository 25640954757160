// src/components/CodeOfConduct.js
import React, { useState, useEffect } from 'react';
import contentData from '../data/codeOfConductContent.json';
import ReactMarkdown from 'react-markdown';


const CodeOfConduct = () => {
  const [activeSection, setActiveSection] = useState('');

  // Função para rolar até a seção selecionada com animação suave
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.history.pushState(null, null, `#${id}`);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Função para atualizar o tópico ativo baseado na posição de scroll
  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    contentData.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) {
        const elementPosition = element.offsetTop;
        if (scrollPosition >= elementPosition) {
          setActiveSection(item.id);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Remover o listener ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col md:flex-row">
      {/* Menu Lateral */}
      <aside className="md:w-1/4 p-4 h-auto md:h-screen sticky top-0 overflow-y-auto bg-gray-100 border-b md:border-b-0 md:border-r border-gray-300 shadow-md">
        <h2 className="text-xl font-bold mb-6">Tópicos</h2>
        <ul className="space-y-2">
          {contentData.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => scrollToSection(item.id)}
                className={`flex items-center w-full text-left px-2 py-2 rounded transition-colors duration-200 ${
                  activeSection === item.id
                    ? 'bg-blue-100 text-blue-800 font-semibold'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                {activeSection === item.id && (
                  <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                )}
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </aside>

      {/* Conteúdo Principal */}
      <main className="md:w-3/4 p-6 overflow-y-auto h-auto md:h-screen">
        {contentData.map((item) => (
          <section key={item.id} id={item.id} className="mb-12">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">{item.title}</h2>
            <p className="text-base text-gray-700 leading-relaxed whitespace-pre-wrap">
            <ReactMarkdown className="prose">{item.content}</ReactMarkdown>
            </p>
          </section>
        ))}
      </main>
    </div>
  );
};

export default CodeOfConduct;

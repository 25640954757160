// src/components/TextRepository.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';
import TextCard from './TextCard';
import TextFormModal from './TextFormModal';

const TextRepository = () => {
  const { session } = useContext(AuthContext);
  const [texts, setTexts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingText, setEditingText] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('title'); // 'title' or 'reference'

  const fetchTexts = async () => {
    if (!session) return;

    try {
      let query = supabase
        .from('texts')
        .select('*')
        .order('created_at', { ascending: false });

      if (searchTerm.trim() !== '') {
        if (searchField === 'title') {
          query = query.ilike('title', `%${searchTerm}%`);
        } else if (searchField === 'reference') {
          query = query.ilike('reference', `%${searchTerm}%`);
        } else {
          // Busca em ambos os campos
          query = query.or(
            `title.ilike.%${searchTerm}%,reference.ilike.%${searchTerm}%`
          );
        }
      }

      const { data, error } = await query;

      if (error) {
        console.error('Erro ao buscar textos:', error);
      } else {
        setTexts(data);
      }
    } catch (error) {
      console.error('Erro ao buscar textos:', error);
    }
  };

  useEffect(() => {
    fetchTexts();
  }, [session, searchTerm, searchField]);

  const handleAddText = () => {
    setEditingText(null);
    setIsModalOpen(true);
  };

  const handleEditText = (text) => {
    setEditingText(text);
    setIsModalOpen(true);
  };

  const handleDeleteText = async (textId) => {
    const confirmDelete = window.confirm('Tem certeza que deseja excluir este texto?');
    if (!confirmDelete) return;

    try {
      const { error } = await supabase
        .from('texts')
        .delete()
        .eq('id', textId);

      if (error) {
        throw error;
      }

      // Atualizar a lista de textos
      fetchTexts();
    } catch (error) {
      console.error('Erro ao excluir texto:', error);
      alert('Erro ao excluir texto.');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditingText(null);
    fetchTexts();
  };

  return (
    <div className="p-6">
      {/* Barra de Busca */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="flex items-center w-full md:w-auto mb-2 md:mb-0">
          <select
            className="p-2 border rounded mr-2"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="title">Título</option>
            <option value="reference">Referência</option>
            <option value="both">Título e Referência</option>
          </select>
          <input
            type="text"
            placeholder={`Buscar por ${searchField === 'both' ? 'título ou referência' : searchField}...`}
            className="p-2 border rounded w-full md:w-64"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          onClick={handleAddText}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full md:w-auto"
        >
          Adicionar Texto
        </button>
      </div>

      {/* Modal para adicionar/editar texto */}
      {isModalOpen && (
        <TextFormModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          initialData={editingText}
        />
      )}

      {/* Lista de Textos */}
      <div className="space-y-4">
        {texts.map((text) => (
          <TextCard
            key={text.id}
            text={text}
            onEdit={() => handleEditText(text)}
            onDelete={() => handleDeleteText(text.id)}
          />
        ))}
      </div>

      {texts.length === 0 && (
        <p className="text-gray-700 mt-4">Nenhum texto encontrado.</p>
      )}
    </div>
  );
};

export default TextRepository;

// src/components/Spreadsheet.js
import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Importar estilos padrão
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Importar tema

const Spreadsheet = () => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef(); // Referência para a API do grid

  // Função para buscar dados do Supabase
  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from('clients').select('*');
      if (error) {
        console.error('Erro ao buscar dados:', error);
      } else {
        setRowData(data);
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  // Definir as colunas com base nos dados
  useEffect(() => {
    if (rowData.length > 0) {
      const cols = Object.keys(rowData[0]).map((key) => {
        let column = {
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
        };

        // Determinar o tipo de dados para filtragem
        const sampleValue = rowData[0][key];
        if (typeof sampleValue === 'number') {
          column.filter = 'agNumberColumnFilter';
        } else if (
          typeof sampleValue === 'string' &&
          !isNaN(Date.parse(sampleValue))
        ) {
          column.filter = 'agDateColumnFilter';
          column.valueFormatter = (params) => {
            return new Date(params.value).toLocaleDateString();
          };
        } else {
          column.filter = 'agTextColumnFilter';
        }

        return column;
      });
      setColumnDefs(cols);
    }
  }, [rowData]);

  // Buscar dados ao montar o componente
  useEffect(() => {
    fetchData();
  }, []);

  // Definir filtros rápidos
  const predefinedFilters = [
    {
        name: 'Grandes Despesas em Viagens',
        description: 'Montante > 2000 e Categoria = Viagem',
        applyFilter: () => {
          gridRef.current.api.setFilterModel({
            Teste: {
              type: 'greaterThan',
              filter: 2000,
            },
          });
        },
      },
    {
      name: 'Limpar Filtros',
      description: 'Remover todos os filtros',
      applyFilter: () => {
        gridRef.current.api.setFilterModel(null);
      },
    },
  ];

  // Função para lidar com a seleção de filtros
  const handleFilterSelection = (filter) => {
    filter.applyFilter();
    gridRef.current.api.onFilterChanged();
  };

  // Função para lidar com a edição das células
  async function onCellValueChanged(params) {
    try {
      const { error } = await supabase
        .from('transactions')
        .update({ [params.colDef.field]: params.newValue })
        .eq('id', params.data.id);

      if (error) {
        console.error('Erro ao atualizar:', error);
        alert('Erro ao atualizar o registro.');
      }
    } catch (error) {
      console.error('Erro ao atualizar:', error);
    }
  }

  return (
    <div className="flex flex-col md:flex-row p-4">
      {/* Barra Lateral com Filtros Rápidos */}
      <div className="md:w-1/4 md:pr-4 mb-4 md:mb-0">
        <div className="bg-white rounded-md shadow-md p-4">
          <h2 className="text-lg font-semibold mb-4">Filtros Rápidos</h2>
          <ul className="space-y-2">
            {predefinedFilters.map((filter, index) => (
              <li key={index}>
                <button
                  onClick={() => handleFilterSelection(filter)}
                  className="w-full text-left px-2 py-2 rounded bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
                >
                  {filter.name}
                </button>
                <p className="text-sm text-gray-500">{filter.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Container da Planilha */}
      <div className="md:w-3/4">
        <div
          className="ag-theme-alpine"
          style={{ height: '80vh', width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              filter: true,
              sortable: true,
              resizable: true,
              editable: true,
            }}
            onCellValueChanged={onCellValueChanged}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default Spreadsheet;

// src/components/FileCard.js
import React from 'react';
import { supabase } from '../supabaseClient';
import { TrashIcon } from '@heroicons/react/24/outline';

const FileCard = ({ file, onDelete }) => {
  const handleDownload = async () => {
    try {
      const { data, error } = await supabase.storage
        .from('file-repository')
        .download(file.file_name);
      if (error) {
        throw error;
      }

      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.title);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      alert('Erro ao baixar o arquivo.');
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      `Tem certeza de que deseja excluir o arquivo "${file.title}"?`
    );
    if (!confirmDelete) return;

    try {
      // Remover o arquivo do Supabase Storage
      const { error: storageError } = await supabase.storage
        .from('file-repository')
        .remove([file.file_name]);

      if (storageError) {
        throw storageError;
      }

      // Remover o registro do banco de dados
      const { error: dbError } = await supabase
        .from('files')
        .delete()
        .eq('id', file.id);

      if (dbError) {
        throw dbError;
      }

      // Atualizar a lista de arquivos no componente pai
      onDelete();
    } catch (error) {
      console.error('Erro ao excluir o arquivo:', error);
      alert('Erro ao excluir o arquivo.');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-start">
          <h3 className="text-lg font-semibold mb-2">{file.title}</h3>
          <button onClick={handleDelete} title="Excluir">
            <TrashIcon className="h-5 w-5 text-red-600 hover:text-red-800" />
          </button>
        </div>
        <p className="text-gray-600 mb-2 line-clamp-3">{file.description}</p>
        <p className="text-sm text-gray-500">
          Categoria: {file.categories.name}
        </p>
      </div>
      <div className="mt-4">
        <button
          className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default FileCard;

// src/components/Tab3.js
import React from 'react';

const Tab4 = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Futuro Dashboard</h1>
      <p>Larga de Ser curioso</p>
    </div>
  );
};

export default Tab4;

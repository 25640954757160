// src/components/AuthForm.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Logo from './img/logo_otm.png';
import './AuthForm.css';

const AuthForm = () => {
  const [formState, setFormState] = useState('signIn'); // 'signIn' ou 'signUp'
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Campos do formulário
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Campos adicionais para registro
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');

  const handleSignIn = async () => {
    setLoading(true);
    setErrorMessage('');
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleSignUp = async () => {
    setLoading(true);
    setErrorMessage('');
    const { error } = await supabase.auth.signUp(
      {
        email,
        password,
        options: {
          data: {
            full_name: fullName,
            phone,
          },
        },
      }
    );
    if (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState === 'signIn') {
      handleSignIn();
    } else {
      handleSignUp();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-600 to-purple-600 p-4">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md transform transition-all duration-500">
      <img src={Logo} alt="Logo" className="logo" />
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          {formState === 'signIn' ? 'Bem-vindo de volta!' : 'Crie sua conta'}
        </h2>
        {errorMessage && (
          <div className="bg-red-100 text-red-700 p-3 mb-4 rounded-md">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {formState === 'signUp' && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700">Nome Completo</label>
                <input
                  type="text"
                  placeholder="Seu nome completo"
                  className="w-full p-3 mt-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Telefone</label>
                <input
                  type="tel"
                  placeholder="(XX) XXXXX-XXXX"
                  className="w-full p-3 mt-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              placeholder="seuemail@dominio.com"
              className="w-full p-3 mt-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Senha</label>
            <input
              type="password"
              placeholder="********"
              className="w-full p-3 mt-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading
              ? 'Carregando...'
              : formState === 'signIn'
              ? 'Entrar'
              : 'Registrar'}
          </button>
        </form>
        <p className="mt-6 text-sm text-center text-gray-700">
          {/*{formState === 'signIn'
            ? 'Não tem uma conta?'
            : 'Já possui uma conta?'}{' '} */}
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => {
              setErrorMessage('');
              setFormState(formState === 'signIn' ? 'signUp' : 'signIn');
            }}
          >
            {/* {formState === 'signIn' ? 'Registrar-se' : 'Entrar'} */}
          </span>
        </p>
      </div>
    </div>
  );
};

export default AuthForm;

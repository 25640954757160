// src/components/TextCard.js
import React from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

const TextCard = ({ text, onEdit, onDelete }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex justify-between items-start mb-2">
        <h3 className="text-xl font-semibold">{text.title}</h3>
        <div className="flex space-x-2">
          <button onClick={onEdit} title="Editar">
            <PencilIcon className="h-5 w-5 text-blue-600 hover:text-blue-800" />
          </button>
          <button onClick={onDelete} title="Excluir">
            <TrashIcon className="h-5 w-5 text-red-600 hover:text-red-800" />
          </button>
        </div>
      </div>
      {text.reference && (
        <p className="text-sm text-gray-500 mb-2">Referência: {text.reference}</p>
      )}
      <p className="text-gray-700 whitespace-pre-wrap">{text.content}</p>
    </div>
  );
};

export default TextCard;

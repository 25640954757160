// src/components/TextFormModal.js
import React, { useState, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';

const TextFormModal = ({ isOpen, onClose, initialData }) => {
  const { session } = useContext(AuthContext);
  const [title, setTitle] = useState(initialData ? initialData.title : '');
  const [reference, setReference] = useState(initialData ? initialData.reference : '');
  const [content, setContent] = useState(initialData ? initialData.content : '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!title || !content) {
      alert('Por favor, preencha os campos obrigatórios.');
      return;
    }

    if (!session) {
      alert('Usuário não autenticado.');
      return;
    }

    setLoading(true);

    try {
      if (initialData) {
        // Atualizar texto existente
        const { error } = await supabase
          .from('texts')
          .update({
            title,
            reference,
            content,
          })
          .eq('id', initialData.id);

        if (error) {
          throw error;
        }
      } else {
        // Inserir novo texto
        const { error } = await supabase.from('texts').insert([
          {
            user_id: session.user.id,
            title,
            reference,
            content,
          },
        ]);

        if (error) {
          throw error;
        }
      }

      // Fechar o modal e atualizar a lista
      onClose();
    } catch (error) {
      console.error('Erro ao salvar texto:', error);
      alert('Erro ao salvar texto.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-2xl">
        <h2 className="text-xl font-bold mb-4">
          {initialData ? 'Editar Texto' : 'Adicionar Texto'}
        </h2>
        <div className="mb-4">
          <label className="block text-gray-700">Título *</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Referência</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Conteúdo *</label>
          <textarea
            className="w-full p-2 border rounded h-40"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
          >
            Cancelar
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TextFormModal;

// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Login from './AuthForm';

const ProtectedRoute = ({ children }) => {
  const { session } = useContext(AuthContext);

  if (!session) {
    return <Login />;
  }

  return children;
};

export default ProtectedRoute;

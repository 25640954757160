// src/components/Navbar.js
import React from 'react';
import { supabase } from '../supabaseClient';
import Logo from './img/logo_otm.png';
import './Navbar.css';

const Navbar = ({ currentTab, setCurrentTab }) => {

  const tabs = [
    { id: 'home', label: 'Início' },
    { id: 'HornBook', label: 'Cartilha' },
    { id: 'TextRepository', label: 'Textos' },
    { id: 'FileRepository', label: 'Arquivos' },
    { id: 'Dashboard', label: 'Dashboard' },
    { id: 'Spreadsheet', label: 'Clientes' },
    // Adicione mais abas conforme necessário
  ];

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Erro ao deslogar:', error.message);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-4 flex items-center justify-between">
        
        <div className="flex items-center">
          <div className="text-xl font-bold text-blue-600">
            <img src={Logo} alt="Logo" className="logo_header" />
          </div>
          <div className="hidden md:flex space-x-4 ml-6">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  currentTab === tab.id
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => setCurrentTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-100"
            onClick={handleLogout}
          >
            Sair
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

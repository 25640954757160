// src/components/Home.js
import React from 'react';

const Home = ({ setCurrentTab }) => {
  const tabs = [
    {
      id: 'Cartilha',
      title: 'Cartilha',
      description: 'A cartilha consiste em uma série de instruções e informações sobre sobre a conduta de um assessor.',
    },
    {
      id: 'Textos',
      title: 'Textos',
      description: 'Série de textos para auxiliar o assessor com respostas do dia a dia.',
    },
    {
      id: 'Arquivos',
      title: 'Arquivos',
      description: 'Repositório de arquivos, desde planilhas a apresentações que irão estar presentes no dia a dia do assessor.',
    },
    {
      id: 'DashBoard',
      title: 'DashBoard',
      description: 'Em Breve.',
    },
    {
      id: 'Clientes',
      title: 'Clientes',
      description: 'Em Breve',
    },
    {
      id: 'Leads',
      title: 'Leads',
      description: 'Em Breve',
    },
    
    // Adicione mais abas conforme necessário
  ];

  return (
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
        >
          <div className="p-6">
            <h2 className="text-xl font-semibold mb-2">{tab.title}</h2>
            <p className="text-gray-600 mb-4">{tab.description}</p>
            <button
              className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded"
              onClick={() => setCurrentTab(tab.id)}
            >
              Acessar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;

// src/components/FileUploadModal.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';

const FileUploadModal = ({ isOpen, onClose, onUploadSuccess }) => {
  const { session } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [uploading, setUploading] = useState(false);
  const [addingCategory, setAddingCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const { data, error } = await supabase.from('categories').select('*');
    if (error) {
      console.error('Erro ao buscar categorias:', error);
    } else {
      console.error('Categorias carregadas', data);
      setCategories(data);
    }
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      alert('Por favor, insira um nome para a categoria.');
      return;
    }

    try {
      const { data, error } = await supabase.from('categories').insert([
        { name: newCategoryName.trim() },
      ]);

      if (error) {
        throw error;
      }

      // Atualizar a lista de categorias
      await fetchCategories();

      // Selecionar a nova categoria
      if (data && data.length > 0) {
        setCategoryId(data[0].id);
      }

      // Limpar o campo e fechar a seção de adição
      setNewCategoryName('');
      setAddingCategory(false);
    } catch (error) {
      console.error('Erro ao adicionar categoria:', error);
      alert('Erro ao adicionar a categoria.');
    }
  };

  const handleUpload = async () => {
    if (!file || !title || !categoryId) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    if (!session) {
      alert('Usuário não autenticado.');
      return;
    }

    setUploading(true);

    try {
      const userId = session.user.id;
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}_${userId}.${fileExt}`;
      const filePath = `${fileName}`;

      // Upload do arquivo para o Supabase Storage
      let { error: uploadError } = await supabase.storage
        .from('file-repository')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Inserir metadados no Supabase
      const { error: insertError } = await supabase.from('files').insert([
        {
          user_id: userId,
          category_id: categoryId,
          title,
          description,
          file_name: filePath,
        },
      ]);

      if (insertError) {
        throw insertError;
      }

      // Limpar o formulário
      setFile(null);
      setTitle('');
      setDescription('');
      setCategoryId('');
      onUploadSuccess();
      onClose();
    } catch (error) {
      console.error('Erro no upload:', error);
      alert('Erro ao fazer o upload do arquivo.');
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Upload de Arquivo</h2>
        {/* Formulário de upload */}
        <div className="mb-4">
          <label className="block text-gray-700">Título *</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Descrição</label>
          <textarea
            className="w-full p-2 border rounded"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Categoria *</label>
          <div className="flex items-center">
            <select
              className="w-full p-2 border rounded"
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Selecione uma categoria</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => setAddingCategory(!addingCategory)}
              className="ml-2 text-blue-600 hover:text-blue-800"
              title="Adicionar Categoria"
            >
              +
            </button>
          </div>
          {addingCategory && (
            <div className="mt-2">
              <input
                type="text"
                className="w-full p-2 border rounded mb-2"
                placeholder="Nome da nova categoria"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
              <div className="flex justify-end">
                <button
                  onClick={() => setAddingCategory(false)}
                  className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600 mr-2"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleAddCategory}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                >
                  Adicionar
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Arquivo *</label>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
          >
            Cancelar
          </button>
          <button
            onClick={handleUpload}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            disabled={uploading}
          >
            {uploading ? 'Enviando...' : 'Enviar'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
